<template>
   <a-modal title="批量分配" v-model="visible" centered :confirmLoading="confirmLoading"
    cancelText="取消" okText="保存" width="400px"
    @ok="handleOk" @cancel="handleCancel">
        <a-form layout='inline'>
            <a-form-item label="请选择">
                <a-select style="width: 200px" :not-found-content="fetching ? undefined : null"  placeholder="请选择负责人" v-model="import_to_employee" showSearch :filterOption="filterOption">
                    <template slot="notFoundContent">
                        <div style="height:50px">
                            <vue-element-loading :active="fetching" color="#00cca2" spinner="spinner"/>
                        </div>
                    </template>
                    <a-select-option v-for="(d, index) of consultants" :key="index" :value="d['course_consultant_id']">
                        <div>
                            <LImg style="width:24px;height:24px;border-radius:50%;margin-right:8px" :src="d['employee_avatar']"/>
                            <span>{{ d['filter_name'] }}</span>
                        </div>
                    </a-select-option>
                </a-select>
            </a-form-item>
        </a-form>
  </a-modal>
</template>

<script>
    export default {
        inject: ['parent'],
        name:'distribution',
        props:['customerId','isTab'],
        data() {
            return {
                visible: false,
                fetching: false,
                consultants:[],
                confirmLoading: false,
                import_to_employee:undefined
            }
        },
        created () {
            this.visible = true
            this.getConsultant()
        },
        beforeDestroy() {
            this.visible = false
        },
        methods:{
            filterOption(input, option) {
                return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
            },
            async getConsultant() {
                this.fetching = true
                let res = await this.$store.dispatch('searchConsultantAction', {})
                this.consultants = res.data
                this.fetching = false
            },
            async handleOk(){
                try {
                    const { import_to_employee } = this
                    if(import_to_employee){
                        if(this.isTab == '1'){
                            let res = await this.$store.dispatch('cCustomerPoolTransferAction', { customer_id:this.customerId,employee_id: import_to_employee})
                        }else{
                            let res = await this.$store.dispatch('cLeadsTransferAction', { task_id:this.customerId,employee_id: import_to_employee})
                        }
                        this.parent.hideDistribution(1)
                    }else{
                        this.$message.warning('请选择!')
                    }
                } catch {
                }
            },
            handleCancel(){
                this.parent.hideDistribution()
            }
        }
    }
</script>