<template>
    <div @click="hideFollowModal" style="height:100%">
        <a-breadcrumb class="breadcrumb">
            <a-breadcrumb-item>首页</a-breadcrumb-item>
            <a-breadcrumb-item>招生咨询</a-breadcrumb-item>
            <a-breadcrumb-item>客户公海</a-breadcrumb-item>
        </a-breadcrumb>
        <div v-if="customer_pool" class="main-box">
            <div class="clearfix table-tools">
                <div class="search">
                    <a-form layout='inline' v-if="selectedRowKeys.length == 0" @submit="toScreen">
                        <a-form-item>
                            <a-input v-model="keyword" style="width: 140px"  allowClear placeholder="客户名称/电话"></a-input>
                        </a-form-item>
                        <a-form-item>
                            <a-select v-model="studioId" mode="multiple" :maxTagCount="1" :maxTagTextLength='5' allowClear showSearch placeholder="所属校区" :filterOption="filterOption" style="width: 220px;">
                               <a-select-option v-for="(d, index) of studios" :key="index" :value="d['studio_id']">{{ d['filter_name'] }}</a-select-option>
                            </a-select>
                        </a-form-item>
                        <a-form-item>
                            <a-button @click="toScreen" html-type="submit" :loading="loading" type="primary" >搜索</a-button>
                        </a-form-item>
                    </a-form>
                    <a-form layout='inline' v-else>
                        <a-form-item>
                            已选择<span>{{selectedRowKeys.length}}</span>项
                        </a-form-item>
                        <a-form-item>
                            <a-divider type="vertical" />
                        </a-form-item>
                        <a-form-item v-if="canDistribute">
                            <a-button @click="showDistribution" icon="apartment">分配</a-button>
                        </a-form-item>
                        <a-form-item v-if="canReceive">
                            <a-button @click="toReceive" icon="user-add">领取</a-button>
                        </a-form-item>
                        <!-- <a-form-item v-if="canDelete">
                            <a-button icon="delete">删除</a-button>
                        </a-form-item> -->
                    </a-form>


                </div>
            </div>
            <div @click.stop class="table">
                <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
                <a-table size="middle" :pagination="false" :bordered='false' rowKey="customer_id"
                    :selection="{key: 'customer_id'}"
                    :rowSelection="{selectedRowKeys: selectedRowKeys, onChange: onSelectChange}"
                    :columns="columns" :dataSource="list" @change="handleChange" :customRow="clickRow" :rowClassName="rowClassName" :scroll="{ x: 1300 }">
                    <template slot="index" slot-scope="text, record , index">
                        <span>{{(index+1) + pageParams.perPage * (pageParams.currentPage - 1)}}</span>
                    </template>

                     <template slot="name" slot-scope="text, record">
                        <div style="">
                            <a style="display:block;max-width:90px;overflow:hidden;text-overflow:ellipsis;white-space: nowrap;">{{ text }}</a>
                            <!-- <svg style="width: 16px;height: 16px;margin-left: 6px;" aria-hidden="true">
                                <use v-show="record.gender == 2" xlink:href="#icon-sex_woman"></use>
                                <use v-show="record.gender == 1" xlink:href="#icon-sex_man"></use>
                            </svg> -->
                         </div>

                         <span style="font-size:10px;color: rgba(69, 90, 100, 0.6);">
                                ({{record.gender}}{{record.year?','+record.year+'岁':''}})                             
                        </span>
                    </template>

                     <template slot="cellphone" slot-scope="text,record">
                         <div>
                             {{record.cellphone}}
                         </div>
                         <span style="font-size:10px;color: rgba(69, 90, 100, 0.6);" v-if="record.cellphone_geo">
                             ({{record.cellphone_geo.province}},{{record.cellphone_geo.op}})
                         </span>
                    </template>
                    
                    <template slot="action" slot-scope="text,record">
                        <a href="javascript:;" @click="showFollowModal($event, record)">跟进</a>
                    </template>
                </a-table>
            </div>
            <div class="page">
                <a-pagination
                :pageSizeOptions="pageSizeOptions"
                :total="pageParams.totalCount"
                showSizeChanger
                :pageSize="pageParams.perPage"
                v-model="current"
                @change="changePage"
                @showSizeChange="onShowSizeChange"
                :showTotal="total => `共 ${pageParams.totalCount.toLocaleString()}条 `"
                >
                    <template slot='buildOptionText' slot-scope='props'>
                    <span v-if="props.value!=='100'">{{props.value}}条/页</span>
                    <span v-if="props.value==='100'">100条/页</span>
                    </template>
                </a-pagination>
            </div>
        </div>
        <div v-else class="main-box" style="height:300px;display:flex;align-items:center;justify-content:center">
            <a-empty :image="simpleImage" description='未开启客户公海规则' />
        </div>
        <!-- <followModal :authType="authType" :item="modalData" :leadsId='leadsId' isTab='1' v-if="followVisible && canRead"/> -->
        <uploadModal v-if="isUploadModal" isTab='1' />
        <distribution v-if="isDistribution" isTab='1' :customerId='selectedRowKeys'/>
    </div>
</template>
<script>
const columns = [
  { title: '#', width:'55px', scopedSlots: { customRender: 'index' }, key: 'index',fixed: 'left'},
  {title: '客户名称',width:"110px", dataIndex: 'customer_name', key: '1' ,fixed: 'left', scopedSlots: { customRender: 'name' },ellipsis: true},
  {title: '联系电话', width:'100px',dataIndex: 'cellphone', key: 'cellphone' ,fixed: 'left',scopedSlots: { customRender: 'cellphone' }},
  {title: '所属校区', dataIndex: 'studio_id', key: 'studio_id' ,sorter:()=>{}},
  {title: '来源渠道', dataIndex: 'channel_id', key: '2' ,sorter:()=>{}},
  {title: '添加人', dataIndex: 'created_by', key: 'created_by' ,sorter:()=>{}},
  {title: '更新时间', dataIndex: 'update_time', key: 'update_time' ,sorter:()=>{}},
  {title: '添加时间', dataIndex: 'create_time', key: 'create_time' ,sorter:()=>{}},
//   { title: '操作', key: 'operation', fixed: 'right', width:80, scopedSlots: { customRender: 'action' }}
]

import followModal from '@/views/clientresource/modal/followModal/index'
import tableMixins from '@/common/mixins/table'
import authority from '@/common/mixins/authority'
import distribution from './distribution'
import moment from 'moment'
import { Empty } from 'ant-design-vue';

export default {
    name:'seas',
    provide() {
        return {
            parent: this
        }
    },
    mixins: [ tableMixins , authority ],
    components:{
        followModal,
        distribution
    },
    data(){
        return{
            loading: false,
            exportLoading:false,
            list:[],
            pageSizeOptions: ['10', '20', '30', '40', '100'],
            current: 1,
            columns,
            selectedRowKeys:[],
            pageParams: {
                currentPage: 1,
                pageCount: 50,
                perPage: 10,
                totalCount: 0
            },
            searchParams: {
                "page": 1,
                "per-page": 10,
                "search": {},
                "sort": ''
            },
            modalData: {},
            followVisible:false,
            isUploadModal:false,
            isDistribution:false,
            leadsId:'',
            clickId:'',
            studios:[],
            customerName:'',
            keyword:'',
            phoneNumber:undefined,
            studioId:undefined,
            authType:['crm','pool'],
            customer_pool:true,
        }
    },
    beforeCreate() {
        this.simpleImage = Empty.PRESENTED_IMAGE_SIMPLE;
    },
    created(){
        this.getStudio()
    },
    methods:{
        filterOption(input, option) {
            return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
        },
        async getList() {
            this.loading = true
            let res = await this.$store.dispatch('cCustomerPoolAction', this.searchParams)
            if(res.items){
                this.list = res.items
                this.pageParams = res._meta
            }else{
                this.customer_pool = res.data.customer_pool
            }
            this.loading = false
        },
        async getStudio() {
            let res = await this.$store.dispatch('searchBelongStudioAction', {})
            this.studios = res.data
        },
        showUploadModal(){
            this.isUploadModal = true
        },
        hideUploadModal(){
            this.isUploadModal = false
        },
        clickRow(record, index) {
            return {
                on: {
                    click: () => {
                        this.clickId = record.customer_id
                        this.showFollowModal(null, record)
                    }
                }
            }
        },
        rowClassName(record, index) {
            let className = "";
            if (record.customer_id == this.clickId) {
                className = "rowActive";
                this.$emit('change', this.clickId)
            }
            return className;
        },
        showFollowModal(e, item) {
            e && e.stopPropagation()
            this.modalData = item
            this.leadsId = item.customer_id
            this.followVisible = true
            return 
        },
        hideFollowModal(type) {
            if (type === 1) {
                this.getList()
            }
            this.followVisible = false
        },
        handleChange(pagination, filters, sorter){
            console.log(pagination, filters, sorter)
            return false
            if(sorter.order){
                if(sorter.order === "ascend"){
                    this.searchParams['sort'] = `${sorter.field}`
                }else{
                    this.searchParams['sort'] = `-${sorter.field}`
                }
                }else{
                this.searchParams['sort'] = ''
                }
            this.getList();
        },
        toScreen(e){
            e?e.preventDefault():''
            this.searchParams.search.studio_id = this.studioId
            this.searchParams.search.keyword = this.keyword
            this.getList()
        },
        showDistribution(){
            this.isDistribution = true
        },
        hideDistribution(num){
            if(num){
                this.selectedRowKeys = [];
                this.$message.success('操作成功!')
                this.getList()
            }
            this.isDistribution = false
        },
        toReceive(){
            let that = this
            this.$confirm({
                title: `确定要领取该客户吗?`,
                okText: '确定',
                okType: 'danger',
                cancelText: '取消',
                onOk() {
                return new Promise(async (resolve, reject) => {
                    let res = await that.$store.dispatch('cCustomerPoolReceiveAction', { customer_id: that.selectedRowKeys })
                    if (res) {
                        that.selectedRowKeys = [];
                        that.$message.success('操作成功!')
                        that.getList()
                        resolve(res)
                    }
                }).catch(error => console.log(error))
                }
            })
        },
        async toExport(){
            this.exportLoading = true
            let exportData = {
                search:this.searchParams.search,
                sort:this.searchParams.sort
            }
            exportData.search.task_id = this.selectedRowKeys
            let res = await this.$store.dispatch('cCustomerPoolExportAction', exportData)
            const blob = new Blob([res], {type: 'application/vnd.ms-excel'})
            if ('download' in document.createElement('a')) { 
                const link = document.createElement('a')
                link.download = `客户公海.${moment().format('YYYY.MM.DD')}.xls`
                link.style.display = 'none'
                link.href = URL.createObjectURL(blob)
                document.body.appendChild(link)
                link.click()
                URL.revokeObjectURL(link.href) 
                document.body.removeChild(link)
            } else { //其他浏览器
                navigator.msSaveBlob(blob, fileName)
            }
            this.exportLoading = false
        }
    }
}
</script>
<style lang="scss">
  .rowActive{
    background:#e6fff6
  }
</style>